import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Landing } from "../Component/Landing";
import NotFound from "../Component/NotFound";

export const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} exact={true}></Route>
          <Route path="/error" element={<NotFound />} exact={true}></Route>
          <Route path="*" element={<Navigate to="/" />} exact={true}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};
