import React, { useEffect, useState } from "react";
import axios from "axios";
import "../Css/custom.css";
import { uuidv4 } from "../Component/utils";
import logo from "../Images/file.png";
import { useNavigate } from "react-router-dom";
import classes from "./Landing.module.css";
import { useLocation } from "react-router-dom";
import InfoModal from "./InfoModal";

export const Landing = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [language, setLanguage] = useState("en");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  // const [infoModal, setInfoModal] = useState(true);

  const location = useLocation();
  const [extRef, setExtRef] = useState(null);
  const navigate = useNavigate();

  // const closeHandler = () => {
  //   setInfoModal(false);
  // };

  const handleContinue = () => {
    let evinaRequestId;
    if (extRef === null) {
      evinaRequestId = uuidv4();
    } else {
      evinaRequestId = extRef;
    }

    console.log("Evina Request id in request pin---", evinaRequestId);
    axios
      .post("https://tmobilecallback.h2ndigital.com/ani", {
        language,
        evinaRequestId,
      })
      .then((response) => {
        console.log("Data sent successfully", response.data);

        if (response.data.response) {
          console.log("response", response.data.response);
          window.location.href = response.data.response;
        } else {
          navigate("/error");
        }
      })
      .catch((error) => {
        console.error("Error sending data to backend", error);
        setNotificationMessage(
          "Ups! Coś poszło nie tak podczas przetwarzania Twojego żądania. Spróbuj ponownie później."
        );
      });
  };
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ext_ref = urlParams.get("ext_ref");
    setExtRef(ext_ref);
    console.log(ext_ref, "msp");
  }, []);

  return (
    <div className="landing-page">
      <header>
        <img src={logo} alt="Logo" className="logo" />
        <p className="logo_text">Glam World</p>
      </header>
      <main>
        {isSubmitted ? (
          <div>
            <p>{notificationMessage}</p>
          </div>
        ) : (
          <>
            <div className={classes.center_container}>
              <div className={classes.text_container}>
                <p className={classes.text}>
                  Zanurz się w spokojnym pięknie plażowych krajobrazów i
                  urzekających filmach z dziewczynami. Odkryj oszałamiające
                  efekty wizualne i wciągające doświadczenia, które jednym
                  kliknięciem przeniosą Cię do rajskich wybrzeży.
                </p>
              </div>
            </div>
            <div className={classes.center_container}>
              <button onClick={handleContinue}>Subskrybuj</button>
            </div>
            <div className="disclaimer-section">
              <h2 style={{ color: "#000" }}>
                Subskrybcja,"GlamWorld" opłata 9,90 PLN co 7 dni
                {/* Subskrybuj usługę GlamWorld zgadzasz się z następującymi
                warunkami */}
              </h2>
              <ul className="points" style={{ color: "#000" }}>
                {/* <li style={{color:'#000'}}>
                  Opłata w wysokości <span className="largeText">9.9 PLN</span>{" "}
                  z VAT będzie pobierana co Co tydzień.
                </li> */}
                <li style={{ color: "#000" }}>
                  Usługa ma charakter subskrypcyjny. Opłata będzie doliczana do
                  Twojego rachunku u Twojego operatora (telefon na abonament)
                  lub odliczana od środków na koncie (telefon na kartę).
                </li>
                <li style={{ color: "#000" }}>
                  Usługę możesz dezaktywować w dowolnym momencie poprzez
                  wysłanie SMS-a o treści:{" "}
                  <span className="largeText">STOP GLAMWORLD</span> na numer
                  80711 (Orange), 80713 (T-Mobile) - koszt:{" "}
                  <span className="largeText">0 PLN.</span>
                </li>
                <li style={{ color: "#000" }}>
                  Organizator: K&C Offshore SAL, underground -1 floor, Main
                  Road, Zouk Mosbeh, Beirut - Lebanon. Masz pytania? Napisz:{" "}
                  <a
                    href="http://vas@kncee.com/"
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "blue",
                    }}
                  >
                    vas@kncee.com
                  </a>{" "}
                  lub zadzwoń: 22 244 40 22 (pon. - pt. w godz. 9.30-17.00).
                </li>
                <li style={{ color: "#000" }}>
                  Regulamin <span className="largeText">GlamWorld</span>{" "}
                  dostępny pod adresem : {" "}
                  <a
                    href="https://www.poland.videocentral.org/terms"
                    style={{ color: "blue" }}
                  >
                    T&Cs
                  </a>
                  .
                </li>
              </ul>
            </div>
          </>
        )}
      </main>
      {/* {infoModal && (
        <InfoModal infoModal={infoModal} closeHandler={closeHandler} />
      )} */}
    </div>
  );
};
